<template>
  <div class="map-modal">
    <yandex-map :settings="settings" :coords="coords">
      <ymap-marker marker-id="0" :coords="coords" :icon="icon" />
    </yandex-map>
  </div>
</template>

<script>
import { yandexMap, ymapMarker } from "vue-yandex-maps";

export default {
  name: "MapModal",
  props: ["options"],
  data() {
    return {
      coords: [0, 0],
      settings: {
        apiKey: "82575734-3306-42a4-9097-ef18c9e3b0ac",
        lang: "ru_RU",
        coordorder: "latlong",
        version: "2.1",
      },
    };
  },
  computed: {
    icon() {
      return {
        layout: "default#imageWithContent",
        imageHref: "/public/images/map.svg",
        imageSize: [108, 74],
        imageOffset: [-54, -74],
      };
    },
  },
  mounted() {
    window.ymaps.ready(() => {
      window.ymaps.geocode(this.options.address).then((res) => {
        this.coords = res.geoObjects.get(0).geometry.getCoordinates();
      });
    });
  },
  components: {
    yandexMap,
    ymapMarker,
  },
};
</script>

<style lang="stylus">
@import "~@/styles/global/global.styl"
.map-modal
  width 80vw
  height 60vh
  border-radius radius
  overflow hidden

  +below(480px)
    width calc(100vw - 60px)
    height 80vh

  .ymap-container
    width 100%
    height 100%

  &__hint
    background main_white
    padding 6px 12px
    font-size 16px
    font-weight bold
    position absolute
    width 200px
    border-radius 8px
</style>
