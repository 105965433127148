<template>
  <main class="main">
    <section class="container">
      <BreadcrumbsComponent :title="page ? page.title : 'Контакты'" />
      <div class="container--full">
        <VueGoodTable
          :columns="columns"
          :pagination-options="{ enabled: false }"
          :rows="companies"
          :select-options="{ enabled: false }"
          compactMode
        >
          <template slot="table-row" slot-scope="props">
            <span v-if="props.column.field === 'office'">{{ props.row.title }}</span>
            <span v-if="props.column.field === 'address'">{{ props.row.address }}</span>
            <a v-if="props.column.field === 'actions'" href="#" @click.prevent="showOnMap(props.row)"
              >Показать на карте</a
            >
          </template>
          <div slot="emptystate">
            <span>Данных нет</span>
          </div>
        </VueGoodTable>
      </div>
    </section>
  </main>
</template>

<script>
import BreadcrumbsComponent from "components/BreadcrumbsComponent.vue";
import MapComponent from "components/modals/Map.vue";
import { VueGoodTable } from "vue-good-table";
import { loadYmap } from "vue-yandex-maps";
import { cityIn } from "lvovich";

export default {
  name: "ContactsPage",
  async asyncData({ store, link }) {
    store.state.alternateLink = link;
    await store.dispatch("GET_CONTACTS_PAGE");
  },
  data() {
    return {
      settings: {
        apiKey: "82575734-3306-42a4-9097-ef18c9e3b0ac",
        lang: "ru_RU",
        coordorder: "latlong",
        version: "2.1",
      },
      columns: [
        {
          label: "Офис",
          field: "office",
        },
        {
          label: "Контактные данные",
          field: "address",
        },
        {
          label: "",
          field: "actions",
        },
      ],
    };
  },
  computed: {
    page() {
      let page;
      if (this.$store.state.contacts_page && this.$store.state.contacts_page.page) {
        page = this.$store.state.contacts_page.page;
      }
      return page;
    },
    companies() {
      if (
        this.$store.state.contacts_page &&
        this.$store.state.contacts_page.companies &&
        this.$store.state.contacts_page.companies.length
      ) {
        return this.$store.state.contacts_page.companies;
      } else {
        return [];
      }
    },
  },
  beforeMount() {
    loadYmap({
      ...this.settings,
    });
  },
  methods: {
    showOnMap(data) {
      if (window.ymaps) {
        this.$store.state._modals.push({
          component: MapComponent,
          options: data,
        });
      }
    },
    getImg(item) {
      if (item.head_img && item.head_img.img && item.head_img.img.url) {
        return this.$store.state.api + item.head_img.img.url;
      }
    },
  },
  metaInfo() {
    let api = this.$store.state.api;
    let metaNamePage = this.page.title;
    let metaFullPath = this.$route.fullPath;
    let alternateLink = this.$store.state.alternateLink;
    let page = {};
    let city = this.$store.state.cities.find((c) => c.title_eng === this.$store.state.geoData.city);
    city = cityIn(city ? city.title : "Казань");
    if (this.$store.state.contacts_page && this.$store.state.contacts_page.page) {
      page = this.$store.state.contacts_page.page;
    }
    const title = (page.meta_title || page.title).replace(/{{ city }}/g, `в ${city}`);
    let img = this.getImg(page) || "";
    return {
      title,
      script: [
        {
          type: "application/ld+json",
          json: {
            "@context": "https://schema.org",
            "@type": "WebPage",
            name: title,
            description: page.meta_description?.replace(/{{ city }}/g, `в ${city}`),
          },
        },
        {
          type: "application/ld+json",
          json: {
            "@context": "http://www.schema.org",
            "@type": "BreadcrumbList",
            itemListElement: [
              {
                "@type": "ListItem",
                position: "1",
                name: "Главная",
                item: api,
              },
              {
                "@type": "ListItem",
                position: "2",
                name: metaNamePage,
                item: api + metaFullPath,
              },
            ],
          },
        },
      ],
      link: [
        { rel: "canonical", href: alternateLink },
        { rel: "alternate", hreflang: "x-default", href: alternateLink },
      ],
      meta: [
        {
          vmid: "keywords",
          name: "keywords",
          content: page.meta_keywords,
        },
        {
          vmid: "description",
          name: "description",
          content: page.meta_description?.replace(/{{ city }}/g, `в ${city}`),
        },
        {
          vmid: "og:title",
          name: "og:title",
          content: title,
        },
        {
          vmid: "og:image",
          name: "og:image",
          content: img,
        },
        {
          vmid: "og:description",
          name: "og:description",
          content: page.meta_description?.replace(/{{ city }}/g, `в ${city}`),
        },
      ],
    };
  },
  components: {
    BreadcrumbsComponent,
    VueGoodTable,
  },
};
</script>

<style lang="stylus">
@import "~@/styles/global/global.styl"
@import "~@/styles/parts/vue-good-table.styl"
</style>
